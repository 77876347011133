import React from "react";

const BackgroundBlobs = () => {
  return (
    <div className="mix-blend-add absolute flex h-full w-full animate-spin items-center justify-center blur-2xl">
      <div className="animation-delay-2 absolute left-[50%] h-1/3 w-1/3 animate-blob rounded-full bg-emerald-200 opacity-80"></div>
      <div className="animation-delay-4 absolute right-[50%] top-[40%] h-1/3 w-1/3 animate-blob rounded-full bg-indigo-200 opacity-80"></div>
      <div className="h- absolute top-[42.5%] h-1/3 w-1/3 animate-blob rounded-full bg-fuchsia-200 opacity-80"></div>
    </div>
  );
};

export default BackgroundBlobs;
