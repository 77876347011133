import React from "react";

import BackgroundBlobs from "./components/BackgroundBlobs";
import BusinessCard from "./components/BusinessCard";

function App() {
  return (
    <div className="absolute flex h-full w-full animate-fade-in items-center justify-center overflow-hidden bg-gradient-to-br from-rose-50 to-sky-200">
      <BackgroundBlobs />
      <BusinessCard />
      <div className="absolute bottom-2 text-xs text-slate-900 opacity-10">
        Copyright © 2022 by Beniamin Czerwoński
      </div>
    </div>
  );
}

export default App;
