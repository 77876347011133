import React, { useRef, Suspense } from "react";
import { useCopyToClipboard, useHover } from "usehooks-ts";
import { ReactComponent as CopyIcon } from "../resources/copy-regular.svg";
import Image from "./Image";

const BusinessCard = () => {
  const {
    REACT_APP_EMAIL: email,
    REACT_APP_FIRSTNAME: firstName,
    REACT_APP_LASTNAME: lastName,
    REACT_APP_DOMAIN: domain,
    REACT_APP_TITLE: title,
  } = process.env;

  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const [copiedValue, copy] = useCopyToClipboard();

  return (
    <Suspense fallback={null}>
      <div className="group flex scale-100 animate-fade-in flex-col gap-4 p-4 drop-shadow-2xl vertical:flex-row ">
        <Image />
        <div className="font-mono">
          <div
            className="cursor-pointer bg-gradient-to-br from-sky-700 to-rose-700 bg-clip-text font-extrabold text-transparent"
            onClick={() => copy(email!)}
            ref={hoverRef}
          >
            <div className="text-8xl">
              {firstName}
              <span className="text-4xl">@</span>
            </div>
            <div className="text-8xl">
              {lastName}
              <span className="text-4xl">.{domain}</span>
            </div>
          </div>
          <div className="inline-flex w-full justify-between text-3xl">
            <div className="text-slate-700 ">{title}</div>
            <div
              className={`text-slate-500 opacity-0 ${
                (isHover || copiedValue === email) && "opacity-100"
              } flex items-center gap-2 transition-opacity delay-500`}
            >
              {copiedValue === email ? "Copied" : "Copy"}
              <CopyIcon className="h-6 fill-slate-500" />
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default BusinessCard;
