import React from "react";
import face from "../resources/face.jpg";

const BackgroundBlobs = () => {
  return (
    <img
      src={face}
      alt="face"
      className={`ratio-square relative h-96 w-96 rounded-2xl transition-transform delay-150 ease-in-out group-hover:scale-105 vertical:h-56 vertical:w-56`}
    />
  );
};

export default BackgroundBlobs;
